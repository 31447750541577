@use '@angular/material' as mat;
@import './palette.scss';


$accent: mat.define-palette($paletteAccent, 500, A200, A100, A400);
$app-primary: mat.define-palette($paletteApp);

$app-theme: mat.define-light-theme((
  color: (
    primary: $app-primary,
    accent: $accent
    )
    ));
    
@include mat.all-component-themes($app-theme);

.menu-lateral{
  mat-list-item.active{
    mat-icon, div{
      color: mat.get-color-from-palette($app-primary, 500)!important;
    }
  }
}
// AJUSTE AQUI CONFORME CONTRASTE COM A COR DO TEMA
.mat-toolbar.mat-primary{
//   color: #fff;
}
.mat-mdc-raised-button.mat-primary, .mat-mdc-unelevated-button.mat-primary{
//   --mdc-filled-button-label-text-color: #fff;
//   color: var(--mdc-filled-button-label-text-color);
}